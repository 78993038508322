<template>
  <div class="index-page">
    <router-view></router-view>
  </div>
</template>

<script>
</script>

<style lang="scss">
</style>
